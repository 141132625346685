import * as React from 'react';

import NewsList from '../components/NewsList';

export default class News extends React.Component {
  render() {
    return (
      <NewsList/>
    );
  }
}