import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Header,
  Segment,
} from 'semantic-ui-react';

import BaseContainer from '../components/BaseContainer';
import NewsController from '../../controllers/NewsController';
import { NewsModel } from '../../shared/models';

interface Props {
  match: any
}

export default class NewsDetail extends React.Component<Props> {
  state = {
    item: null as NewsModel | null
  };

  constructor(props: Props) {
    super(props);

    NewsController.select(this.props.match.params.id).then((item: NewsModel) => {
      this.setState({ item });
    });
  }

  render() {
    const { item } = this.state;
    if (item) {
      return (
        <BaseContainer>
          <Header as='h1'>
            <NavLink to={`/news`}>&lt; News & Updates</NavLink>
          </Header>
          <Segment>
            <div style={{float: 'right'}}>
              {item.PublishedDate.format('MM/DD/YYYY')}
            </div>
            <Header as='h1'>{item.Title}</Header>
            <div dangerouslySetInnerHTML={{ __html: item.MainContent }}/>
          </Segment>
        </BaseContainer>
      );
    }
    return <div/>;
  }
}