import * as React from 'react';
// import {
//   Image,
// } from 'semantic-ui-react';

import BaseContainer from '../components/BaseContainer';
import NewsList from '../components/NewsList';

export default class Home extends React.Component {

  render() {
    return (
      <div>
        <BaseContainer textAlign="center">
          {/* <Image size='big' src='/img/workbench_rs.jpeg' bordered rounded /> */}
          <h2 className="brand-before">
            <small>Welcome to</small>
          </h2>
          <h1 className="brand-name">hobbytown of Boston</h1>
        </BaseContainer>
        <NewsList limit={1} linkToNews={true}/>
      </div>
    );
  }

};