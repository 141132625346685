import Api from '../shared/api';
import Http from '../shared/http';

import { FaqModel } from '../shared/models';

export default class FaqController extends Api {

  public static list(): Promise<FaqModel[]> {
    return Http.get('faq/list').then((result: FaqModel[]) => {
      if (result === undefined) {
        return Promise.resolve([]);
      }
      else {
        result = result.sort((n1,n2) => {
          if (n1.SortOrder > n2.SortOrder) {
            return 1;
          }
          if (n1.SortOrder < n2.SortOrder) {
            return -1;
          }
          return 0;
        });
        return Promise.resolve(result);
      }
    });
  }
  
}