import * as moment from 'moment';
import Api from '../shared/api';
import Http from '../shared/http';

import { NewsModel } from '../shared/models';

export default class NewsController extends Api {

  public static list(): Promise<NewsModel[] | undefined> {
    return Http.get('news/list').then((result: NewsModel[] | undefined) => {
      if (result === undefined) {
        return Promise.resolve([]);
      }
      else {
        result.map(elem => {
          elem.PublishedDate = moment(elem.PublishedDate);
        })
        result = result.sort((n1,n2) => {
          if (n1.PublishedDate < n2.PublishedDate) {
            return 1;
          }
          if (n1.PublishedDate > n2.PublishedDate) {
            return -1;
          }
          return 0;
        });
        return Promise.resolve(result);
      }
    });
  }

  public static select(id: string): Promise<NewsModel | undefined> {
    return Http.get('news/' + id).then((result: NewsModel | undefined) => {
      if (result === undefined) {
        return Promise.resolve(new NewsModel());
      }
      else {
        result.PublishedDate = moment(result.PublishedDate);
        return Promise.resolve(result);
      }
    });
  }
  
}
