import * as React from 'react';
import {
  Accordion,
  Header,
  Icon,
  Message
} from 'semantic-ui-react';

import BaseContainer from '../components/BaseContainer';
import FaqController from '../../controllers/FaqController';
import { FaqModel } from '../../shared/models';

export default class Faq extends React.Component {
  state = { 
    activeIndex: -1,
    faqs: [] as FaqModel[],
    isLoading: false as boolean
  }

  componentDidMount() {
    this.setState({isLoading: true}, () => 
      FaqController.list().then(faqs => this.setState({faqs, isLoading: false}))
    );
  }

  handleClick = (e: {}, titleProps: {index: number}) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex, faqs/*, isLoading*/ } = this.state;

    return (
      <BaseContainer /*isLoading={isLoading}*/>
        <Header as='h2'>FAQ</Header>

        {faqs.map((x: FaqModel, i: number) => (
          <Accordion key={i}>
            <Accordion.Title
              active={activeIndex === i}
              index={i}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />
              {i + 1}. {x.Title}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === i}>
              <Message 
                content={<div dangerouslySetInnerHTML={{__html: x.Content}} />}
                // info 
                header={x.Title} 
              />
            </Accordion.Content>
          </Accordion>
        ))}
      </BaseContainer>
    );
  }

};