function getDefaultOptions(): RequestInit {
  return {
    headers: new Headers(
      {
        'Content-Type': 'application/json',
        'Action': 'application/json'
      }
    )
  };
}

export default class Http {
  private static baseUrl: string = '';

  public static setup(baseUrl: string, show: () => void, hide: () => void, unauth: () => void) {
    this.baseUrl = baseUrl;
    this.show = show;
    this.hide = hide;
    this.unauth = unauth;
  }

  public static get<T>(url: string): Promise<T | undefined> {
    const options = getDefaultOptions();
    options.method = 'GET';
    return this.request(url, options);
  }

  public static getText(url: string): string {
    var request = new XMLHttpRequest();

    request.open('GET', url, false);
    request.send(null);

    if (request.status === 200) {
      return request.responseText;
    }

    return '';
  }

  public static delete<TData, TResult>(url: string, data: TData): Promise<TResult | undefined> {
    const options = getDefaultOptions();
    options.method = 'DELETE';
    options.body = JSON.stringify(data);
    return this.request(url, options);
  }

  public static post<TData, TResult>(url: string, data: TData): Promise<TResult | undefined> {
    const options = getDefaultOptions();
    options.method = 'POST';
    options.body = JSON.stringify(data);
    return this.request(url, options);
  }

  private static request<T>(url: string, options: RequestInit): Promise<T | undefined> {
    this.show();
    return new Promise<T>(resolve => {
      fetch(this.urlTimeStamp(`${this.baseUrl}/${url}`), options).then(response => {
        if (response.ok) {
          return response.json();
        }
        if (response.status === 401) {
          this.unauth();
        }
        return Promise.resolve(undefined);
      }).then((json: {} | undefined) => {
        this.hide();
        try {
          resolve(<T> json);
        } catch {
          resolve(undefined);
        }
      }).catch((e) => {
        this.hide();
        resolve(undefined);
      });
    });
  }

  private static urlTimeStamp(url: string): string {
    const timestamp = new Date().getTime();
    let ts: string;
    if (url.indexOf('?') >= 0) {
      ts = `&ts=${timestamp}`;
    } else {
      ts = `?ts=${timestamp}`;
    }
    return `${url}${ts}`;
  }

  private static show: () => void = () => {
    return;
  }

  private static hide: () => void = () => {
    return;
  }

  private static unauth: () => void = () => {
    return;
  }
}
