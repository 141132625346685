import * as moment from 'moment';

export class FaqModel {
  id: string = '';
  Title: string;
  Content: string;
  SortOrder: number;
}

export class KitCategoryModel {
  id: string = '';
  Title: string;
  SortOrder: number;
  Kits: KitModel[]
}

export class KitModel {
  id: string = '';
  KitCategoryId: string = '';
  KitNumber: string;
  KitTitle: string;
  Price: number;
}

export class NavItem {
  children?: NavItem[];
  divider?: boolean;
  component?: any;
  path: string;
  label: string;
  exact?: boolean;
  leftNav?: boolean;
}

export class NewsModel {
  _id: string = '';
  Title: string;
  MainContent: string;
  PublishedDate: moment.Moment;
  SummaryContent: string;
}

export class NewsletterSubscriberModel {
  EmailAddress: string;
  IpAddress?: string;
  CreatedDate?: moment.Moment;
}

export class OrderItemModel {
  InventoryId: string;
  Number: string;
  Title: string;
  MainImageUrl?: string;
  MinQuantity: number;
  Price: number;
  Quantity: number;
}

export class PartModel {
  id: string = '';
  MainImage: string;
  MainImageUrl?: string;
  MinQuantity: number;
  PartNumber: string;
  PartTitle: string;
  Price: number;
}