import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

class ScrollToTop extends React.Component<RouteComponentProps, {}> {
  
  constructor(props: RouteComponentProps) {
    super(props);
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
  
}

export default withRouter(ScrollToTop);