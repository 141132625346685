import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {
  Container,
  Grid,
  Header,
  Image,
  List,
  Segment
} from 'semantic-ui-react';

export default class AppFooter extends React.Component {

  renderListItem(to: string, label: string) {
    return (
      <List.Item>
        <NavLink to={to}>{label}</NavLink>
      </List.Item>
    );
  }

  render() {
    return (
      <Segment className='box footer' style={{ padding: '4em 0em 2em' }}>
        <Container textAlign='center'>
          <Grid divided stackable>
            <Grid.Column width={3}>
              <List>
                {this.renderListItem('/', 'Home')}
                {this.renderListItem('/news', 'News')}
                {/* {this.renderListItem('/', 'Log In')}
                {this.renderListItem('/', 'Register')} */}
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <List>
                {this.renderListItem('/kits-parts/kit-catalog', 'Kit Catalog')}
                {this.renderListItem('/kits-parts/parts-list', 'Parts List')}
                {this.renderListItem('/kits-parts/aerotrain', 'Aerotrain')}
                {this.renderListItem('/kits-parts/excess-parts', 'Excess Parts')}
                {this.renderListItem('/kits-parts/other-products', 'Other Products')}
                {this.renderListItem('/kits-parts/misc-info', 'Misc. Technical Info')}
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <List>
                {/* {this.renderListItem('/outside-links', 'Outside Links')} */}
                {this.renderListItem('/faq', 'Faq')}
                {/* {this.renderListItem('/contact-us', 'Contact Us')} */}
                {this.renderListItem('/order-form', 'Order Form')}
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' content='Hours of Operation' />
              <p>
                M - F<br/>
                9AM - 4PM
              </p>
              <p>
                <Image centered rounded size='mini' src='/img/logo.png' />&nbsp;
                Copyright &copy; hobbytown of Boston 2021
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    );
  }
}