import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container, Dimmer, Header, Loader, Segment } from 'semantic-ui-react';

import BaseContainer from './components/BaseContainer';
// import NewsletterSignup from './components/NewsletterSignup';
import PdfDownload from './components/PdfDownload';

import Faq from './pages/Faq';
import Home from './pages/Home';
import KitCatalog from './pages/KitCatalog';
import News from './pages/News';
import OrderFormList from './pages/OrderForm';
import PartsList from './pages/PartsList';

import Api from '../shared/api';
import Http from '../shared/http';
import { NavItem } from '../shared/models';
import ScrollToTop from './components/ScrollToTop';
import ResponsiveContainer from './ResponsiveContainer';
import './App.css';

interface EmptyComponentProps {
  pdf?: string;
  title: string;
}
class EmptyComponent extends React.Component<EmptyComponentProps, {}> {

  constructor(props: EmptyComponentProps) {
    super(props);
  }

  render() {
    return (
      <BaseContainer>
        <Header as='h2'>{this.props.title}</Header>
        {this.props.pdf ? <PdfDownload url={this.props.pdf} /> : <p>Coming Soon</p>}
      </BaseContainer>
    );
  }
}

export default class App extends React.Component {
  state = {
    loading: false as boolean
  };

  constructor(props: {}) {
    super(props);

    Http.setup(Api.BaseUrl, this.handleShowLoader, this.handleHideLoader, this.handleHideLoader);
  }

  render() {
    const routes = this.getRoutes();
    return (
      <Router>
        <ResponsiveContainer routes={routes}>
          <ScrollToTop />
          <Dimmer active={this.state.loading} page={true}>
            <Loader content='Loading' />
          </Dimmer>
          <Switch>
            {routes.map((route) => this.renderRoute(route))}
            <Route component={this.renderNoMatch} />
          </Switch>
          {/* <NewsletterSignup/> */}
        </ResponsiveContainer>
      </Router>
    );
  }

  private getRoutes = () => [
    {
      path: "/",
      component: Home,
      exact: true,
      label: "Home",
      leftNav: false
    },
    {
      path: "/news",
      component: News,
      exact: true,
      label: "News/Upcoming Events"
    },
    {
      path: "/kits-parts",
      exact: true,
      children: [
        {
          path: "/kits-parts/kit-catalog",
          component: <KitCatalog />,
          exact: true,
          label: "Kit Catalog"
        },
        {
          path: "/kits-parts/parts-list",
          component: <PartsList />,
          exact: true,
          label: "Parts List"
        },
        {
          path: "/kits-parts/aerotrain",
          component: <EmptyComponent pdf="/assets/Aerotrain-Catalog-Page.pdf" title="Aerotrain" />,
          divider: true,
          exact: true,
          label: "Aerotrain"
        },
        {
          path: "/kits-parts/excess-parts",
          component: <EmptyComponent title="Excess/Used Parts"/>,
          exact: true,
          label: "Excess/Used Parts"
        },
        {
          path: "/kits-parts/other-products",
          component: <EmptyComponent title="Other Products"/>,
          exact: true,
          label: "Other Products"
        },
        {
          path: "/kits-parts/misc-info",
          component: <EmptyComponent title="Misc. Technical Info"/>,
          divider: true,
          exact: true,
          label: "Misc. Technical Info"
        }
      ],
      label: "Kits & Parts"
    },
    {
      path: "/outside-links",
      exact: true,
      leftNav: false,
      component: <EmptyComponent title="Outside Links"/>,
      label: "Outside Links"
    },
    {
      path: "/faq",
      exact: true,
      component: Faq,
      label: "FAQ"
    },
    {
      path: "/contact-us",
      exact: true,
      leftNav: false,
      component: <EmptyComponent title="Contact Us"/>,
      label: "Contact Us"
    },
    {
      path: "/order-form",
      exact: true,
      component: <OrderFormList />,
      label: "Order Form"
    }
  ] as NavItem[]

  private handleHideLoader = () => {
    this.setState({loading: false});
  }

  private handleShowLoader = () => {
    this.setState({loading: true});
  }

  private renderNoMatch = ({ location: {} }) => {
    return (
      <div className="content">
        <Segment className="box" vertical={true}>
          <Container text={true}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              No match for <code>{location.pathname}</code>
            </Header>
          </Container>
        </Segment>
      </div>
    );
  }

  private renderRoute = (route: NavItem) => {
    var rte = [];

    const buildRoute = (rt: NavItem) => (
      <Route
        key={rt.path}
        path={rt.path}
        exact={rt.exact}
        render={props => {
          if (typeof(rt.component) === 'function') {
            return <rt.component {...props} />;
          }
          else {
            return rt.component;
          }
        }}
      />
    );

    // top route
    rte.push(buildRoute(route));

    if (route.children) {

      // children
      rte.push(route.children.map((child) => buildRoute(child)));
    }

    return rte;
  }

}