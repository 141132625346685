import * as React from 'react';
import { NavLink, Route } from 'react-router-dom';
import {
  Header,
  Segment,
} from 'semantic-ui-react';

import NewsController from '../../controllers/NewsController';
import { NewsModel } from '../../shared/models';
import BaseContainer from './BaseContainer';
import NewsDetail from './NewsDetail';

interface Props {
  limit?: number | string;
  linkToNews?: boolean | false;
}

export default class News extends React.Component<Props> {
  state = {
    items: [] as NewsModel[]
  };

  constructor(props: Props) {
    super(props);

    NewsController.list().then((items: NewsModel[]) => {
      this.setState({
        items: this.props.limit ? items.splice(0, Number(this.props.limit)) : items
      });
    });
  }

  render() {
    return (
      <div>
        {this.renderBaseRoute()}
        {this.renderContentRoute()}
      </div>
    );
  }

  private renderBaseRoute() {
    const { items } = this.state;
    const route = (
      <BaseContainer>
        <Header as='h1'>
          News &amp; Updates
          {this.renderNavLink()}
        </Header>
        {items.length > 0 
          ? items.map(x => {
            return (
              <Segment key={x._id}>
                <div style={{float: 'right', textAlign: 'right'}}>
                  {x.PublishedDate.format('MM/DD/YYYY')}
                  <div>
                    <NavLink to={`/news/${x._id}`}>(read more)</NavLink>
                  </div>
                </div>
                <Header as='h3'>{x.Title}</Header>
                <div dangerouslySetInnerHTML={{ __html: x.SummaryContent }}/>
              </Segment>
            );
          })
          : <p>There are no results.</p>
        }
      </BaseContainer>
    );
    return this.props.linkToNews ? route : (
      <Route
        path="/news"
        exact={true}
        render={() => route}
      />
    );
  }

  private renderContentRoute() {
    return (
      <Route 
        path="/news/:id" 
        render={props => <NewsDetail {...props}/>}
      />
    );
  }

  private renderNavLink() {
    if (this.props.linkToNews) {
      return <NavLink style={{float: 'right'}} to='/news/'>view all &gt;</NavLink>;
    }
    return <div/>;
  }
}