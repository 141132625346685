import * as React from 'react';
import {
  Button,
  Header,
  Icon,
  Input,
  Image,
  Table,
  TextArea
} from 'semantic-ui-react';

import BaseContainer from '../components/BaseContainer';
import PartController from '../../controllers/PartController';
import { OrderItemModel, PartModel } from '../../shared/models';
import Storage from '../../shared/storage';

export default class OrderFormList extends React.Component {
  state = {
    items: [] as OrderItemModel[]
  };

  constructor(props: {}) {
    super(props);
  }

  componentDidMount() {

    // // temp storage
    // if (Storage.get('order') === null || Storage.getJSON('order').length === 0) {
    //   const tempParts = [
    //     { InventoryId: '1', Quantity: 1 },
    //     { InventoryId: '5', Quantity: 5 },
    //     { InventoryId: '10', Quantity: 10 }
    //   ] as OrderItemModel[];
    //   Storage.setJSON('order', tempParts);
    // }

    // read storage
    const orderStorage = (Storage.getJSON('order') || []) as OrderItemModel[];

    const promises = orderStorage.map(part => PartController.load(part.InventoryId));

    Promise.all(promises).then(results => {
      let items = [] as OrderItemModel[];

      results.map((item: PartModel) => {
        items.push({
          InventoryId: item.id, 
          Number: item.PartNumber, 
          Title: item.PartTitle, 
          MainImageUrl: item.MainImageUrl,
          MinQuantity: item.MinQuantity, 
          Price: item.Price, 
          Quantity: orderStorage.find(x => x.InventoryId == item.id)?.Quantity
        } as OrderItemModel);
      });

      this.setState({ items });
    });
  }

  render() {
    return (
      <BaseContainer>
        <Header as='h1'>
          Order Request Form
        </Header>

        {this.state.items.length > 0 
          ? 
            <React.Fragment>
              {this.renderTable()}
              <div style={{'textAlign': 'right'}}>{this.renderAddParts()}</div>
              {this.renderForm()}
            </React.Fragment>
          : this.renderNoData()
        }

      </BaseContainer>
    );
  }

  private handleQuantityChange = (e: React.SyntheticEvent, data: any) => {
    let items = this.state.items;
    items[data['name']].Quantity = data['value'];

    this.setStorage(items);
    this.setState(items);
  }

  private handleItemRemove = (e: React.SyntheticEvent, data: any) => {
    let items = this.state.items;
    items.splice(data['name'], 1);

    this.setStorage(items);
    this.setState(items);
  }

  private renderAddParts() {
    return (
      <Button 
        color='yellow' 
        content='Add Parts'
        onClick={() => { self.location.href = '/kits-parts/parts-list'}}
      />
    );
  }

  private renderItemPrice(item: OrderItemModel) {
    return (
      <React.Fragment>
        {item.MinQuantity > 1 && item.MinQuantity + '/'}
        ${item.Price.toFixed(2)}
      </React.Fragment>
    );
  }

  private renderItemQuantity(item: OrderItemModel, i: number) {
    return (
      <Input 
        min='1'
        step='1'
        name={i}
        onChange={this.handleQuantityChange}
        style={{width: '75px'}}
        type='number'
        value={item.Quantity} 
      />
    );
  }

  private renderItemRemove(item: OrderItemModel, i: number) {
    return (
      <Button 
        color='red' 
        icon='x' 
        name={i}
        onClick={this.handleItemRemove}
        size='mini'
      />
    );
  }
 
  private renderItemTotal(item: OrderItemModel) {
    return (
      <React.Fragment>
        {item.MinQuantity > 1 && (item.MinQuantity * item.Quantity + '/')}
        ${(item.Quantity * item.Price).toFixed(2)}
      </React.Fragment>
    );
  }

  private renderNoData() {
    return (
      <div>
        <p>Order Form is empty.</p>
        {this.renderAddParts()}
      </div>
    );
  }

  private renderForm() {
    return (
      <div>
      <Table basic='very'>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell><Input/></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell><Input/></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan='2'>
              Notes/Comments<br/>
              <TextArea style={{width: '100%'}} rows='5'/>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan='2' textAlign='right'>
              <Button content='Continue' color='yellow' />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      </div>
    );
  }

  private renderTable() {

    const total = this.state.items.reduce((prev, item) => {
      return prev + (item.Quantity * item.Price);
    }, 0);

    return (
      <Table compact striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Part</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Price</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.items.map((item, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                {item.MainImageUrl ?
                  <Image
                    centered
                    src={item.MainImageUrl}
                    size='tiny'
                  />
                  : <Icon color="grey" name="file image" size="huge" />
                }
              </Table.Cell>
              <Table.Cell>{item.Number} {item.Title}</Table.Cell>
              <Table.Cell textAlign='right'>{this.renderItemPrice(item)}</Table.Cell>
              <Table.Cell>{this.renderItemQuantity(item, i)}</Table.Cell>
              <Table.Cell>{this.renderItemTotal(item)}</Table.Cell>
              <Table.Cell>{this.renderItemRemove(item, i)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan='4' textAlign='right'>
              <strong>Order Subtotal</strong>
            </Table.Cell>
            <Table.Cell>
              ${total.toFixed(2)}
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }

  private setStorage(items: OrderItemModel[]) {
    Storage.setJSON('order', items.map(item => { 
      return { 
        InventoryId: item.InventoryId, 
        Quantity: item.Quantity 
      }
    }));
  }

};