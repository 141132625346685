export type StorageType = 'order';

export default class Storage {

    public static get(key: StorageType) {
        return localStorage.getItem(key);
    }

    public static getJSON(key: StorageType) {
        if (localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key) || '');
        }
        return null;
    }

    public static set(key: StorageType, value: string) {
        localStorage.setItem(key, value);
    }

    public static setJSON<T>(key: StorageType, value: T) {
        Storage.set(key, JSON.stringify(value));
    }
}