import * as React from 'react';

interface Props {
  url: string;
};

export default class PdfDownload extends React.Component<Props, {}> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const url = this.props.url;
    return (
      <div>
        <p style={{ fontSize: '1.33em' }}>
          <a className="ui button" href={url} target="_blank">Download</a>
        </p>
        <div>
          <iframe src={url} width="100%" style={{minHeight: "935px"}}/>
        </div>
      </div>
    );
  }

}