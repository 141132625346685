import * as React from 'react';
import { NavLink} from 'react-router-dom';
import {
  Container,
  Dropdown,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';

import { NavItem } from '../shared/models';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';

interface Props {
  routes: NavItem[];
}
  
class DesktopContainer extends React.Component<Props, {}> {
  state = {
    fixed: false
  }

  constructor(props: Props) {
    super(props);
  }

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    if (location.pathname.toLowerCase().indexOf('/newsletter/preferences') >= 0) {
      return children;
    }

    return (
      <Responsive {...Responsive.onlyComputer}>
        <AppHeader>
          <Visibility
            once={false}
            onBottomPassed={this.showFixedMenu}
            onBottomPassedReverse={this.hideFixedMenu}
          >
            <Segment
              className="navbar headerbar"
              textAlign='center'
              style={{ padding: '1em 0em', margin: '0' }}
              vertical={true}
            >
              <Menu
                borderless
                fixed={fixed ? 'top' : undefined}
                // inverted={fixed}
                pointing={fixed}
                secondary={!fixed}
                // size='large'
              >
                <Container>
                  {fixed && (
                    <Menu.Item header>
                      <NavLink to='/' exact>
                        <Image rounded size='mini' src='/img/logo.png' style={{ marginRight: '1.5em' }} />
                      </NavLink>
                    </Menu.Item>
                  )}
                  {this.props.routes.map((route) => ResponsiveContainer.renderMenuLink(route))}
                </Container>
              </Menu>
            </Segment>
          </Visibility>  
        </AppHeader>

        {children}

        <AppFooter/>
      </Responsive>
    )
  }
}

class MobileContainer extends React.Component<Props, {}> {
  state = {
    sidebarOpened: false
  }

  constructor(props: Props) {
    super(props);
  }

  handlePusherClick = () => {
    const { sidebarOpened } = this.state

    if (sidebarOpened) this.setState({ sidebarOpened: false })
  }

  handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive {...Responsive.onlyMobile}>
        <Sidebar.Pushable>
          <Sidebar as={Menu} animation='uncover' inverted={true} vertical={true} visible={sidebarOpened}>
            {this.props.routes.map((route) => ResponsiveContainer.renderMenuLink(route))}
          </Sidebar>

          <Sidebar.Pusher
            dimmed={sidebarOpened}
            onClick={this.handlePusherClick}
            style={{ minHeight: '100vh' }}
          >
            <Segment
              inverted
              textAlign='center'
              style={{ padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>

            <AppHeader />

            {children}

            <AppFooter/>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
    )
  }
}

export default class ResponsiveContainer extends React.Component<Props, {}> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div>
        <DesktopContainer routes={this.props.routes}>{this.props.children}</DesktopContainer>
        <MobileContainer routes={this.props.routes}>{this.props.children}</MobileContainer>
      </div>
    );
  }
  
  public static renderMenuLink(route: NavItem) {
    if (route.label === '' || route.leftNav === false) {
      return null;
    }

    if (route.children && route.children.length > 0) 
    {
      return (
        <Dropdown item text={route.label} key={route.path}>
          <Dropdown.Menu>
            {route.children.map((child, i) => {
              return (
                <React.Fragment key={i}>
                  {child.divider ? <Dropdown.Divider /> : null}
                  <Dropdown.Item as={NavLink} exact={child.exact} to={child.path}>
                    {child.label}
                  </Dropdown.Item>
                </React.Fragment>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Menu.Item key={route.path}>
          <NavLink
            exact={route.exact}
            to={route.path}
            activeClassName="ui button"
          >
            {route.label}
          </NavLink>
        </Menu.Item>
      );
    }
  }
}