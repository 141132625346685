import Api from '../shared/api';
import Http from '../shared/http';

import { PartModel } from '../shared/models';

export default class PartController extends Api {

  public static async list(): Promise<PartModel[]> {
    return await Http.get('part/list').then((result: PartModel[]) => {
      if (result === undefined) {
        return Promise.resolve([]);
      }
      else {
        return Promise.resolve(result);
      }
    });
  }

  public static async load(id: string): Promise<PartModel | null> {
    return await Http.get('part/' + id).then((result: PartModel) => {
      if (result === undefined) {
        return Promise.resolve(null);
      }
      else {
        return Promise.resolve(result);
      }
    });
  }
  
}