import * as React from 'react';
import {
  Header,
  Table
} from 'semantic-ui-react';

import BaseContainer from '../components/BaseContainer';
import KitController from '../../controllers/KitController';
import { KitCategoryModel } from '../../shared/models';
import PdfDownload from '../components/PdfDownload';

export default class KitList extends React.Component {
  state = {
    items: [] as KitCategoryModel[]
  };

  constructor(props: {}) {
    super(props);

    KitController.list().then((items: KitCategoryModel[]) => {
      this.setState({ items: items });
    });
  }

  render() {
    return (
      <BaseContainer>
        <Header as='h1'>
          Kit Catalog
        </Header>

        {this.state.items.map(x => (
          <div key={x.id} style={{marginTop: '28px'}}>
            <Header as='h3' textAlign='center'>{x.Title}</Header>

            <Table compact striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Kit Number</Table.HeaderCell>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {x.Kits.map(kit => (
                  <Table.Row key={kit.id}>
                    <Table.Cell singleLine>{kit.KitNumber}</Table.Cell>
                    <Table.Cell>{kit.KitTitle}</Table.Cell>
                    <Table.Cell>${kit.Price.toFixed(2)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ))}

        <div style={{marginTop: '28px'}}>
          <PdfDownload url="/assets/2020 - Kit Catalog.pdf" />
        </div>

      </BaseContainer>
    );
  }

};