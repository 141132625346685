import * as React from 'react';
import {
  Button,
  Grid,
  Header,
  Icon,
  Input,
  Image,
  Label,
  Modal,
  Table,
  Container
} from 'semantic-ui-react';

import BaseContainer from '../components/BaseContainer';
import PartController from '../../controllers/PartController';
import { OrderItemModel, PartModel } from '../../shared/models';
import Storage from '../../shared/storage';

export default class PartsList extends React.Component {
  state = {
    isOpen: false as boolean,
    items: [] as PartModel[],
    itemsSearch: [] as PartModel[],
    orderItem: null as OrderItemModel | null,
    viewBy: 'grid' as 'grid' | 'list'
  };

  constructor(props: {}) {
    super(props);

    PartController.list().then((items: PartModel[]) => {
      this.setState({ items, itemsSearch: items });
    });
  }

  render() {
    return (
      <BaseContainer>
        <Header as='h1'>
          Parts List
        </Header>
        
        {this.renderActionBar()}
        {this.state.viewBy === 'grid' ? this.renderGrid() : this.renderTable()}
        {this.renderModal()}
      </BaseContainer>
    );
  }

  private handleOpen = (open: boolean, item: PartModel | null = null) => {
    let orderItem = null;

    if (item !== null) {
      orderItem = {
        InventoryId: item.id,
        Number: item.PartNumber, 
        Title: item.PartTitle, 
        MainImageUrl: item.MainImageUrl,
        MinQuantity: item.MinQuantity, 
        Price: item.Price, 
        Quantity: 1
      } as OrderItemModel;
    }

    this.setState({ 
      isOpen: open,
      orderItem
    });
  }

  private handleSave = () => {
    let orderItem = this.state.orderItem;

    if (orderItem === null) {
      return;
    }

    // get orders from storage
    let items = (Storage.getJSON('order') || []) as OrderItemModel[];
    // check for item in order
    let item = items.find(x => x.InventoryId === orderItem?.InventoryId);

    // add item or update order quantity
    if (item) {
      item.Quantity = +item.Quantity + +orderItem.Quantity;
    } else {
      items.push({
        InventoryId: orderItem.InventoryId,
        Quantity: orderItem.Quantity
      } as OrderItemModel);
    }

    // update storage
    Storage.setJSON('order', items);

    // close modal
    this.handleOpen(false);
  }

  private handleQuantityChange = (e: React.SyntheticEvent, data: any) => {
    let orderItem = this.state.orderItem;

    if (orderItem === null) {
      return;
    }

    orderItem.Quantity = data['value'];

    this.setState(orderItem);
  }

  private renderActionBar() {
    const { items, viewBy } = this.state;

    return (
      <div style={{ marginBottom: '12px' }}>
        <div style={{ marginBottom: '12px' }}>
          <Input 
            fluid 
            icon='search' 
            onChange={(event, data) => {
              let itemsSearch = items;
              if (data.value !== '') {
                data.value = data.value.toLowerCase();
                itemsSearch = items.filter(x => 
                  x.PartNumber.toLowerCase().indexOf(data.value) > -1 
                    || x.PartTitle.toLowerCase().indexOf(data.value) > -1
                );
              }
              this.setState({itemsSearch});
            }}
            placeholder='Search Part Number or Name...' 
          />
        </div>
        <div style={{textAlign: 'right'}}>
          <strong>{this.state.itemsSearch.length} Parts Found</strong>
          <Button.Group icon style={{marginLeft: '12px'}}>
            <Button 
              active={viewBy === 'grid'} 
              icon='grid layout' 
              onClick={() => this.setState({viewBy: 'grid'})}
            />
            <Button 
              active={viewBy === 'list'} 
              icon='list' 
              onClick={() => this.setState({viewBy: 'list'})}
            />
          </Button.Group>
        </div>
      </div>
    );
  }

  private renderGrid() {
    if (this.state.itemsSearch.length === 0) {
      return null;
    }

    return (
      <Grid>
        {this.state.itemsSearch.map((item, i) => (
          <Grid.Column key={i} width={4} textAlign='center' className='part-item'>
            <div style={{height: '82px'}}>
              {item.MainImage ? 
                  <Image
                    centered
                    src={item.MainImageUrl}
                    size='tiny'
                  />
                : <Icon color="grey" name="file image" size="huge" />
              }
            </div>
            <div>
              {item.PartNumber} {item.PartTitle}
            </div>
            <Button as='div' labelPosition='left'>
              <Label basic style={{cursor: 'default'}}>
                {this.renderItemPrice(item)}
              </Label>
              {this.renderOrderButton(item)}
            </Button>
          </Grid.Column>
        ))}
      </Grid>
    );
  }

  private renderItemPrice(item: OrderItemModel | PartModel) {
    return (
      <React.Fragment>
        {item.MinQuantity > 1 && item.MinQuantity + '/'}
        ${item.Price.toFixed(2)}
      </React.Fragment>
    );
  }

  private renderModal() {
    const item = this.state.orderItem;

    if (item == null) {
      return;
    }

    return (
      <Modal
        open={this.state.isOpen}
        size='small'
      >
        <Modal.Header>Order</Modal.Header>
        <Modal.Content>
          
          <Container textAlign='center'>
            {item.MainImageUrl ? 
              <Image src={item.MainImageUrl} centered />
              : <Icon color="grey" name="file image" size="huge" />
            }
          </Container>

          <Table compact striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Part</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Price</Table.HeaderCell>
                <Table.HeaderCell>Qty</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{item.Number} {item.Title}</Table.Cell>
                <Table.Cell textAlign='right'>{this.renderItemPrice(item)}</Table.Cell>
                <Table.Cell>
                  <Input 
                    min='1'
                    step='1'
                    onChange={this.handleQuantityChange}
                    style={{width: '75px'}}
                    type='number'
                    value={item.Quantity} 
                  />
                </Table.Cell>
                <Table.Cell>
                  {item.MinQuantity > 1 && (item.MinQuantity * item.Quantity + '/')}
                  ${(item.Quantity * item.Price).toFixed(2)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.handleOpen(false)}>Cancel</Button>
          <Button color='yellow' onClick={this.handleSave}>Add to Order</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  private renderOrderButton(item: PartModel) {    
    return (
      <Button 
        icon 
        color='yellow'
        onClick={() => this.handleOpen(true, item)}
      >
        Order
      </Button>
    );
  }

  private renderTable() {
    if (this.state.itemsSearch.length === 0) {
      return null;
    }

    return (
      <Table compact striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {/* <Table.HeaderCell>Number</Table.HeaderCell> */}
            <Table.HeaderCell>Part</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Price</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state.itemsSearch.map((item, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                {item.MainImageUrl ?
                  <Image
                    centered
                    src={item.MainImageUrl}
                    size='tiny'
                  />
                  : <Icon color="grey" name="file image" size="huge" />
                }
              </Table.Cell>
              {/* <Table.Cell singleLine>{item.PartNumber}</Table.Cell> */}
              <Table.Cell>{item.PartNumber} {item.PartTitle}</Table.Cell>
              <Table.Cell textAlign='right'>{this.renderItemPrice(item)}</Table.Cell>
              <Table.Cell>{this.renderOrderButton(item)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

};