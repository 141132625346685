import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {
  Container,
  Image,
} from 'semantic-ui-react';

export default class AppHeader extends React.Component {
  render() {
    return (
      <Container>
        <table>
          <tbody>
            <tr>
              <td style={{width: '20%'}}>
                <div className="brand">
                <NavLink
                  to={'/'}
                >
                  <Image src='/img/logo.png' width="150" bordered rounded />
                </NavLink>
                </div>
              </td>
              <td>
                {this.props.children}
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    );
  }
}