import Api from '../shared/api';
import Http from '../shared/http';

import { KitCategoryModel} from '../shared/models';

export default class KitController extends Api {

  public static async list(): Promise<KitCategoryModel[]> {
    return await Http.get('kit/list').then((result: KitCategoryModel[]) => {
      if (result === undefined) {
        return Promise.resolve([]);
      }
      else {
        // sort categories
        result = result.sort((n1,n2) => {
          if (n1.SortOrder > n2.SortOrder) {
            return 1;
          }
          if (n1.SortOrder < n2.SortOrder) {
            return -1;
          }
          return 0;
        });

        // sort category kits
        result.map((x: KitCategoryModel) => 
          x.Kits = x.Kits.sort((n1,n2) => {
            if (n1.KitNumber > n2.KitNumber) {
              return 1;
            }
            if (n1.KitNumber < n2.KitNumber) {
              return -1;
            }
            return 0;
          })
        )
        return Promise.resolve(result);
      }
    });
  }
  
}