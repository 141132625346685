import * as React from 'react';
import {
  Container,
  Segment
} from 'semantic-ui-react';

interface Props {
  style?: {};
  text?: boolean;
  textAlign?: 'left' | 'right' | 'center';
}

export default class BaseContainer extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Segment className="box" vertical={true}>
        <Container text={this.props.text || true} {...this.props}>
          {this.props.children}
        </Container>
      </Segment>
    );
  }

};